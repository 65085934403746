import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction } from '../../components/CallToAction'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle3, PageTitle2, PageParagraph } from '../../components/PageTitle'
import BenefitsCompounds from '../images/benefitsCompounds.svg'
import BenefitsEasy from '../images/benefitsEasy.svg'
import BenefitsEngaging from '../images/benefitsEngaging.svg'
import BenefitsSecure from '../images/benefitsSecure.svg'
import SalesAdoption from '../images/salesAdoption.png'
import SalesforceDashboard from '../images/salesforceDashboard.png'
import Default from '../../components/Layouts/Default';
import { VideoFrame } from '../../components/VideoFrame';
import DeviceLineup from '../images/deviceLineup.png';
import TeamDash from '../images/teamDash.svg';
import LiveWithCustomer from '../images/liveWithTheCustomer.svg';

import LibertyRing from '../animations/libertyRing.json'
import DesktopSalesforce from '../images/desktopSalesforce.png';


var lottie

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Platform</title>
        </Helmet>


        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                A Better Way To Revenue
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                By working with us, you'll accelerate every aspect of your revenue program
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={SalesforceDashboard} alt="Salesforce Dashboard" />
            </Box>
            <Box width={[1, 1]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
                Real-Time Analytics and Automation
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                Today, 90% of sales funnel analytics/automation takes place outside of Salesforce... in spreadsheets, BI platforms, and various sales tools.  It's hard for your team to get the full picture in real-time, and impossible to build reliable automations on top of it.
                <br />
                <br />
                 Some of the functionality we'll help you replicate natively in Salesforce include:
                <br />
                <br />
                <Bold>Cadencing Analytics</Bold>
                <Bold>Opportunity Progress Analytics</Bold>
                <Bold>Conversation Intelligence Analytics</Bold>
                <Bold>Pipeline Inspection</Bold>
                <Bold>Pipeline Management</Bold>
                <Bold>Adaptive Cadencing</Bold>
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>


        <WrapperBackground
          color={colors.trulyDark}
          background='headsetPerson2'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1" pb="2rem">
              <WidthImg src={SalesAdoption} alt="Sales Adoption" />
            </Box>
            <Box width="1">
              <PageTitle as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              A Future Proof Stack
              </PageTitle>
              <PageParagraph as="p" color={colors.white} mb="1.5rem" center>
                No provider covers as many <u>channels</u> (calls, meetings, email, texts), <u>devices</u> (mobile, desktop, deskphone, web) and <u>integrations</u> (cadencing, CRM, conversation intelligence, etc)
                <br/><br/>
                Once you implement Truly, you'll never have to worry about retraining your team, change managing a new integration, normalizing data or changing reports when you decide to make changes to your sales systems.
                <br/><br/>

              </PageParagraph>
            </Box>
          </Section>
        </WrapperBackground>


        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={LiveWithCustomer} alt="Get Your Company Communicating" />
            </Box>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              Incredible Support
              </PageTitle2>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
                Meet the highest rated support team in the business (just read our raving reviews!)
                <br/><br/>
                With a 2 minute time to response and an experienced team of IT/RevOps professionals, you'll never have to go days/weeks with unresolved issues again.
              </PageParagraph>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperBackground color={colors.trulyDark} background="businessScene1" gradientType="purple">
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1 / 2]}>
              <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
              Expand Sales Visibility
              </PageTitle2>
              <PageParagraph as="p" color={colors.white} mb="1.5rem">
              Understand the building blocks of rep performance. What are they doing? How are they doing it? How efficient are they at each stage of the funnel? Are they focusing on the right opportunities?
              <br/><br/>
              With pre-built Salesforce dashboards, we allow you to answer all of these questions, with powerful drill downs and navigation that allows you to see what happened on every deal.
              <br/><br/>
              And our real-time communications platform will enable you to guide your reps to success by joining their calls and web meetings on-demand, from anywhere, at any time.
              </PageParagraph>
            </Box>
            <Box width={[1 / 2, 1 / 2]} p="2rem">
              <WidthImg src={TeamDash} alt="Team Dashboard" />
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="black">
          <CallToAction/>
        </WrapperBackground>



      </Default>
    )
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`

const Bold = styled.div`
  font-weight: Bold;
`

const Divider = styled.div`
  width: 100%;
  height: 1pt;
  background-color: rgba(255,255,255,0.3);
`
const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: center;
`;